
import { BlocksContext } from "./context/blocksContext"
import { FlatsContext } from "./context/flatsContext"
import {useBlocks} from "./hooks/blocks.hook"
import { Loader } from './components/loader';
import { useFlats } from './hooks/flats.hook';
import { useSendForm } from "./hooks/send-from.hook";
import { useEffect } from "react";

import "./css/style.css";
import "./css/media.css";


function App() {
  const {blocks, setBlocks, popup, setPopup, menuClick, setMenuClick} = useBlocks()
  const {flats, setFlats, activeFlat, setActiveFlat} = useFlats()
  const {getYandexClientId} = useSendForm()

  useEffect(()=>{
    getYandexClientId()
  },[])
  return (
    <div className="App container_main">
      <BlocksContext.Provider value={{blocks,setBlocks,popup,setPopup,menuClick,setMenuClick}}>
        <FlatsContext.Provider value={{flats, setFlats, activeFlat, setActiveFlat}}>
          <Loader />
        </ FlatsContext.Provider>
      </BlocksContext.Provider>
    </div>
  );
}

export default App;
