import { TextField } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import InputMask from 'react-input-mask/lib/react-input-mask.development'
import { BlocksContext } from "../context/blocksContext"
import { useSendForm } from '../hooks/send-from.hook'

export const Header = () => {
    const blocks = useContext(BlocksContext)
    const [opened, setOpened] = useState(false)
    const sendForm = useSendForm()

    const showPopup = (e)=>{
        e.preventDefault()
        document.querySelector('body').classList.add('overflow')
        document.querySelector('.popup_rgba').style.display="block"
        document.querySelectorAll('.popup_main').forEach(el => {
            el.style.display="none"
        });
        console.log(e.currentTarget.getAttribute('data'))
        document.querySelector('.'+e.currentTarget.getAttribute('data')).style.display="block"
    }

    return (
        <React.Fragment>
            {/* <div className={opened?'pu_action pu_action__open':'pu_action'}>
                <div className='pu_action__btn' onClick={(e)=>{e.preventDefault(); blocks.setPopup(true); setOpened(!opened)}}><img src='../images/pu_action_btn.png'/></div>
                <img className='pu_action__img' src={blocks.popup?'../images/pu_action_img.jpg':''} />
                <form className="pu_form">
                    <div className="form_in react_input_style input_name">
                        <TextField name="name" label="Ваше имя"  className="pu_name"/>
                    </div>
                    <div className="form_in react_input_style input_phone">
                        <InputMask mask="+7\ (999) 999-99-99" name="phone" maskChar={null}>
                            <TextField name="phone" label="Ваш телефон"  className="pu_phone"/>
                        </InputMask>
                    </div>
                    <input type="hidden" class="text" value="Ипотека от 0.1%"/>
                    <button className="pu_btn btn_style" celtype="getAction" onClick={(e) => {sendForm.sendForm(e, ()=>{setOpened(false)})}}>УЗНАТЬ ПОДРОБНЕЕ</button>
                </form>
            </div> */}
            <section className="header">
                <div className="cw_main">
                    <div className="hd_inner">
                        <div className="hd_loc">Краснодар</div>
                        <h1><span>ЖК "Событие"</span></h1>
                        <div className="hd_info">
                            Квартиры комфорт + в развитом районе г. Краснодар
                            <span>от  4,2 млн. руб.</span>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}