import React, { useContext, useEffect, useState } from 'react'
import { BlocksContext } from '../context/blocksContext'

export const Comfort = () => {
    const [showImages,setShowImages] = useState(false)

    const loaded = useContext(BlocksContext)

    useEffect(()=>{
        if (loaded.blocks > 3) {
            setShowImages(true)
        }
    },[loaded.blocks])
    return (
        <section className="comfort">
            <div className="cw_main">
                <div className="tm">
                    <sup>В ЖК "Событие" мы продумали</sup>
                    <span>все для вашего комфорта</span>
                </div>
                <div className="comf_list">
                    <div className="cmf_el">
                        <div className="cmf_img">
                            <img src={!showImages?"":"/images/comf_img1.jpg"} />
                            <span>Эргономичные <br/> планировки квартир</span>
                        </div>
                        <div className="cmf_img">
                            <img src={!showImages?"":"/images/comf_img2.jpg"} />
                            <span>Детские и спортивные <br/> площадки</span>
                        </div>
                        <div className="cmf_img">
                            <img src={!showImages?"":"/images/comf_img3.jpg"} />
                            <span>Парковка в комплексе</span>
                        </div>
                    </div>
                    <div className="cmf_el">
                        <div className="cmf_img">
                            <img src={!showImages?"":"/images/comf_img4.jpg"} />
                            <span>Все необходимое на первом этаже</span>
                        </div>
                        <div className="cmf_img">
                            <img src={!showImages?"":"/images/comf_img5.jpg"} />
                            <span>Витражное остекление лоджий</span>
                        </div>
                    </div>
                    <div className="cmf_el">
                        <div className="cmf_img">
                            <img src={!showImages?"":"/images/comf_img6.jpg"} />
                            <span>Видеонаблюдение</span>
                        </div>
                        <div className="cmf_img">
                            <img src={!showImages?"":"/images/comf_img7.jpg"} />
                            <span>Колясочные МОПы</span>
                        </div>
                        <div className="cmf_img">
                            <img src={!showImages?"":"/images/comf_img8.jpg"} />
                            <span>Прогулочные зоны</span>
                        </div>
                    </div>
                </div>
            </div>
		</section>
    )
}